<template>
	<div @click.self="shopShow = false" style="overflow-x: hidden" v-loading.fullscreen.lock="fullscreenLoading">
		<Navigation />
		<!-- 分类区 -->
		<div class="bg_box">
			<div class="ct_box">
				<Nav title="6" />
				<div class="type2 flex">
					<p>行业类型：</p>
					<ul class="flex ul2">
						<li :class="ChannelType.title == '不限' ? 'active' : ''" @click="ChannelShow('不限', 0)">
							不限
						</li>
						<li :class="ChannelType.title == item ? 'active' : ''" v-for="(item, index) in ChannelList"
							:key="index" @click="ChannelShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>

				<div class="type2 flex">
					<p>受众区域：</p>
					<ul class="flex ul2">
						<li :class="TerritoryType.title == '不限' ? 'active' : ''" @click="TerritoryShow('不限', 0)">
							不限
						</li>
						<li :class="TerritoryType.title == item ? 'active' : ''" v-for="(item, index) in TerritoryList"
							:key="index" @click="TerritoryShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>图文价格区间：</p>
					<ul class="flex ul2">
						<li :class="PriceType.title == '不限' ? 'active' : ''" @click="PriceShow('不限', 0)">
							不限
						</li>
						<li :class="PriceType.title == item ? 'active' : ''" v-for="(item, index) in PriceList"
							:key="index" @click="PriceShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>视频价格区间：</p>
					<ul class="flex ul2">
						<li :class="VideoPriceType.title == '不限' ? 'active' : ''" @click="VideoPriceShow('不限', 0)">
							不限
						</li>
						<li :class="VideoPriceType.title == item ? 'active' : ''"
							v-for="(item, index) in VideoPriceList" :key="index" @click="VideoPriceShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>参考粉丝数：</p>
					<ul class="flex ul2">
						<li :class="FansNumType.title == '不限' ? 'active' : ''" @click="FansNumShow('不限', 0)">
							不限
						</li>
						<li :class="FansNumType.title == item ? 'active' : ''" v-for="(item, index) in FansNumList"
							:key="index" @click="FansNumShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>受众性别：</p>
					<ul class="flex ul2">
						<li :class="SexType.title == '不限' ? 'active' : ''" @click="SexShow('不限', 0)">
							不限
						</li>
						<li :class="SexType.title == item ? 'active' : ''" v-for="(item, index) in SexList" :key="index"
							@click="SexShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>受众年龄：</p>
					<ul class="flex ul2">
						<li :class="AgeType.title == '不限' ? 'active' : ''" @click="AgeShow('不限', 0)">
							不限
						</li>
						<li :class="AgeType.title == item ? 'active' : ''" v-for="(item, index) in AgeList" :key="index"
							@click="AgeShow(item, index)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="type2 flex">
					<p>特殊类目：</p>
					<ul class="flex ul2">
						<li :class="SpecialType.title == '不限' ? 'active' : ''" @click="SpecialShow({
		id: 0,
		title: '不限'
	})">不限</li>
						<li v-show="SpecialList.is_pub_weekend" :class="SpecialType.title == '周末可发' ? 'active' : ''" @click="SpecialShow({
		id: 1,
		title: '周末可发'
	})">
							{{ SpecialList.is_pub_weekend }}
						</li>
						<!-- 新加特殊类目项:是否可加配图 是否可加视频 -->
						<li v-show="SpecialList.is_pub_video" :class="SpecialType.title == '可加视频' ? 'active' : ''" @click="SpecialShow({
		id: 2,
		title: '可加视频'
	})">
							{{ SpecialList.is_pub_video }}
						</li>
					</ul>
				</div>
				<div class="type3 flex">
					<p>排序：</p>
					<ul class="flex ul2">
						<li @click="StateShow('默认')" :class="SortType.title == '默认' ? 'active' : ''">
							默认
						</li>
						<li v-if="SortList.member_image_text_price"
							@click="StateShow('图文价格', 'member_image_text_price')" :id="SortType.title == '图文价格' && MoneyState == 1 ? 'activeTrue'
		: SortType.title == '图文价格' && MoneyState == 2 ? 'activeFalse' : ''"
							:class="SortType.title == '图文价格' ? 'active' : ''">
							图文价格
						</li>
						<li v-if="SortList.member_video_price" @click="StateShow('视频价格', 'member_video_price')" :id="SortType.title == '视频价格' && VideoMoneyState == 1 ? 'activeTrue'
		: SortType.title == '视频价格' && VideoMoneyState == 2 ? 'activeFalse' : ''"
							:class="SortType.title == '视频价格' ? 'active' : ''">
							视频价格
						</li>
					</ul>
				</div>
				<div class="type4 flex">
					<p class="type_title_n">媒介名称：</p>
					<el-input @keyup.enter.native="page = 1; ListFn()" v-model="mediaName"
						placeholder="请输入编号或名称"></el-input>
					<div class="flex link_box">
						<p>可发内链：</p>
						<el-select v-model="link" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<div class="flex link_box">
						<p>可发联系方式：</p>
						<el-select v-model="relation" placeholder="请选择">
							<el-option v-for="(item, index) in linkList" :key="index" :label="item" :value="item">
							</el-option>
						</el-select>
					</div>
					<button class="sf_but" type="primary" @click="page = 1; ListFn()">筛选</button>
					<button class="sf_but2" type="primary" @click="page = 1; AllMediaTab()">
						重置筛选
					</button>
					<collect :is_collect="is_collect" @collectShow="collectShow" />
					<mediaWhiteList />
				</div>
			</div>
		</div>
		<table class="table_redBook">
			<thead>
				<td>媒介名称</td>
				<td>小红书号</td>
				<td>图文价格</td>
				<td>视频价格</td>
				<td>行业类型</td>
				<td>所属区域</td>
				<td>图文阅读量</td>
				<td>视频播放量</td>
				<td>粉丝数</td>
				<td>受众性别</td>
				<td>受众年龄</td>
				<td>备注</td>
				<td>操作</td>
			</thead>
			<tbody>
				<tr v-for="(item, index) in tableData" :key="index">
					<td>
						<td_remark :text="item.title" />
					</td>
					<td>
						<td_remark :text="item.account" />
					</td>
					<td>
						<button :class="item.check == 'member_image_text_price' ? 'priceActive' : 'price'"
							@click.stop="onPitch(index, 'member_image_text_price')">￥{{
		item.member_image_text_price.toFixed(2)
	}}
						</button>
					</td>
					<td>
						<button :class="item.check == 'member_video_price' ? 'priceActive' : 'price'"
							@click.stop="onPitch(index, 'member_video_price')">￥{{
		item.member_video_price.toFixed(2)
	}}</button>
					</td>
					<td>{{ item.industry_classify_title }}</td>
					<td>{{ item.area_title }}</td>
					<td>{{ item.image_text_views }}</td>
					<td>{{ item.video_views }}</td>
					<td>{{ item.fans_num }}</td>
					<td>{{ item.audience_sex == 0 ? '不限' : item.audience_sex == 1 ? '女性粉丝偏多' :
		item.audience_sex == 2 ? '男性粉丝偏多' : '男女粉丝均衡' }}</td>
					<td>{{ item.audience_age == 0 ? '不限' : item.audience_age == 1 ? '0-17岁偏多' :
		item.audience_age == 2 ? '18-24岁偏多' : item.audience_age == 0 ? '25-34岁偏多' :
			item.audience_age == 0 ? '35-44岁偏多' : '44岁以上偏多' }}</td>
					<td>
						<td_remark :text="item.remark" />
					</td>
					<td>
						<div class="collect_box flex">
							<p @click.stop="jumpLink(item)">查看案例</p>
							<!-- v-if="item.collect == 0"  -->
							<p v-if="item.is_collect == 0" class="collect" @click.stop="collectGet(item)">收藏</p>
							<!-- v-if="item.collect == 1" -->
							<p v-if="item.is_collect == 1" class="collect2" @click.stop="collectClose(item)">已收藏
							</p>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<!-- 分页器 -->
		<div class="paging_box">
			<pagination :page="page" @pageChange="pageChange" :count="count" :total_page="total_page" />
		</div>
		<!-- 购物车 -->
		<div v-show="shopShow == false" class="shopping_car">
			<div class="car" @click.stop="shopShow = true">
				<p>{{ CarList.length }}</p>
			</div>
		</div>
		<div :class="shopShow ? 'shop_box' : 'shop_none_box'">
			<div class="title flex" style="justify-content: space-between; align-items: center">
				<h3 class="carTitle">已选媒介: {{ CarList.length }}</h3>
				<el-button v-show="CarList.length != 0" type="primary" @click="empty()">清空已选媒介</el-button>
				<span class="close" @click="shopShow = false"></span>
			</div>
			<ul class="listbox" v-show="CarList.length != 0">
				<li v-for="(item, index) in CarList" :key="index">
					<!-- <img :src="item.logo" alt="" /> -->
					<div class="flex over_text">
						<td_remark :text="item.title" />
					</div>
					<p class="price">{{ item.check == 'member_image_text_price' ?
		`图文价格:￥${item.member_image_text_price.toFixed(2)}` :
		`视频价格:￥${item.member_video_price.toFixed(2)}` }}</p>
					<span class="deleteIcon" @click.stop="detale(item)"></span>
				</li>
			</ul>
			<el-empty v-show="CarList.length == 0" description="您还没有选择资源赶紧去看看心仪的资源吧"></el-empty>
			<div class="bottom_box">
				<div class="flex" style="justify-content: space-between; margin-bottom: 1rem">
					<p style="font-size: 1.4rem; color: #999">合计：</p>
					<p class="totalMoney">
						￥<span>{{ totalMoney ? totalMoney : '0.00' }}</span>
					</p>
				</div>
				<button class="put_but" @click="putFn">立即投放</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Nav from '../../components/MediaNav.vue'
export default {
	name: '',
	computed: {
		...mapState(['Media', 'MediaNav', 'TypeItem', 'ActiveChild']),
		totalMoney: function () {
			var n = 0;
			for (var i = 0; i < this.CarList.length; i++) {
				if (this.CarList[i].check == 'member_image_text_price') {
					// this.CarList.push(this.tableData[i])
					n += this.CarList[i].member_image_text_price;
				} else if (this.CarList[i].check == 'member_video_price') {
					// this.CarList.push(this.tableData[i])
					n += this.CarList[i].member_video_price;
				}
			}
			return n.toFixed(2);
		}
	},
	components: {
		Nav,
	},
	created() { },
	mounted() {
		setTimeout(() => {
			for (const i in this.$store.state.Media) {
				if (this.$store.state.Media[i].id == 6) {
					this.ChannelList = this.$store.state.Media[i].industry_list
					this.TerritoryList = this.$store.state.Media[i].area_list
					this.FansNumList = this.$store.state.Media[i].fans_list
					this.PriceList = this.$store.state.Media[i].image_text_price_range
					this.VideoPriceList = this.$store.state.Media[i].image_text_price_range
					this.SpecialList = this.$store.state.Media[i].special_list
					this.SexList = this.$store.state.Media[i].audience_sex_list
					this.AgeList = this.$store.state.Media[i].audience_age_list
					this.SortList = this.$store.state.Media[i].order_list
				}
			}
			this.user_info = this.$util.getLocalStorage('user_info')
			this.ListFn()
		}, 200)
	},
	data() {
		return {
			user_info: {},
			// 频道类型
			ChannelType: {
				id: 0,
				title: '不限',
			},
			ChannelList: [],
			//所属区域
			TerritoryType: {
				id: 0,
				title: '不限',
			},
			TerritoryList: [],
			// 粉丝
			FansNumType: {
				id: 0,
				title: '不限',
			},
			FansNumList: [],
			// 图文价格
			PriceType: {
				id: 0,
				title: '不限',
			},
			PriceList: [],
			// 视频价格
			VideoPriceType: {
				id: 0,
				title: '不限',
			},
			VideoPriceList: [],
			// 价格区间
			MoneyType: {
				id: 0,
				title: '不限',
			},
			MoneyList: [],
			// 特殊类目
			SpecialList: [],
			SpecialType: {
				id: 0,
				title: '不限'
			},
			// 受众性别
			SexList: [],
			SexType: {
				id: 0,
				title: '不限'
			},
			// 受众年龄
			AgeList: [],
			AgeType: {
				id: 0,
				title: '不限'
			},
			// 排序
			SortType: {
				id: 0,
				title: '默认',
			},
			SortList: [],
			MoneyState: 0, 			//图文价格升序降序控制器
			VideoMoneyState: 0, 	//视频升序降序控制器
			/*-----------------媒介筛选------------------------*/
			mediaName: '', //媒介名称,
			linkList: ['是', '否'], //可发链接列表
			link: '', //可发链接
			relation: '', //可发联系方式
			/*数据表格*/
			tableData: [],
			CarList: [],
			// 购物车任务栏切换
			shopShow: false,
			Allcheck: false,
			limit: 0,
			AllcheckNum: 0, //选中总数
			ids: [],
			fullscreenLoading: false,
			total_page: 0, //分页总页数
			count: 0, //总条数
			page: 1,
			is_collect: 0,
		}
	},
	methods: {
		pageChange(pageVal) {
			this.page = pageVal
			this.ListFn()
		},
		StateShow(item, index) {
			/*
			每次点击时需要重置价格-电脑权重-移动权重的状态,在每个判断条件中需做重置
			*/
			this.SortType = {
				id: index,
				title: item,
			}
			switch (item) {
				case '默认':
					this.MoneyState = 0
					this.VideoMoneyState = 0
					this.SortType.id = 0
					break

				case '图文价格':
					this.VideoMoneyState = 0
					if (this.MoneyState == 0) {
						this.MoneyState = 1
					} else if (this.MoneyState == 1) {
						this.MoneyState = 2
					} else {
						this.MoneyState = 1
					}
					break

				case '视频价格':
					this.MoneyState = 0
					if (this.VideoMoneyState == 0) {
						this.VideoMoneyState = 1
					} else if (this.VideoMoneyState == 1) {
						this.VideoMoneyState = 2
					} else {
						this.VideoMoneyState = 1
					}
					break

				default:
					break
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		onPitch(index, type) {
			this.shopShow = true
			console.log('tableDate:', this.tableData[index].check, type);
			// this.tableData[index].check = type
			const result = this.CarList.every(item => item.id !== this.tableData[index].id);
			if (result == true) {
				this.tableData[index].check = type
				this.CarList.push(this.tableData[index])
			} else {
				this.CarList.forEach((item, i) => {
					if (this.CarList[i].id == this.tableData[index].id && this.CarList[i].check != type) {
						console.log(this.CarList[i].check);
						this.tableData[index].check = type
						this.CarList[i].check = type
					} else if (this.CarList[i].id == this.tableData[index].id && this.tableData[index].check == type) {
						console.log(2);
						this.CarList.splice(i, 1)
						this.tableData[index].check = ''
					}
				})
			}
		},
		// 清空购物车
		empty() {
			this.$confirm('是否清空已选媒介?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.fullscreenLoading = true
					// setTimeout(() => {
					this.CarList = []
					this.shopShow = false
					// this.toggleSelection()
					this.tableData.forEach((item, index) => {
						if (item.check) {
							return (item.check = false)
						}
					})
					this.fullscreenLoading = false
					// }, 500)
					this.$message({
						type: 'success',
						message: '已清空已选媒介',
					})
				})
				.catch(() => {
					this.$message({
						type: 'warning',
						message: '已删除',
					})
				})
		},
		createBall(left, top) {
			const bar = document.createElement('ball')
			bar.style.position = 'fixed'
			bar.style.left = left + 'px'
			bar.style.top = top + 'px'
			bar.style.width = '20px'
			bar.style.height = '20px'
			bar.style.borderRadius = '50%'
			bar.style.backgroundColor = 'red'
			bar.style.transition =
				'left 1s linear, top 1s cubic-bezier(0.5, -0.5, 1, 1)'
			document.body.appendChild(bar)
			setTimeout(() => {
				const x = document.body.clientWidth * 1.5
				const y = document.body.clientHeight * 1 - 500
				bar.style.top = y + 'px'
				bar.style.left = x + 'px'
			}, 0)
			bar.ontransitionend = function () {
				this.remove()
			}
		},
		// 结算
		goClose(item) {
			this.$router.push({
				path: '/close',
				query: {},
			})
		},
		// 行业类型切换
		ChannelShow(item, index) {
			this.ChannelType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},

		// 所属区域类型切换
		TerritoryShow(item, index) {
			this.TerritoryType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 图文价格区间
		PriceShow(item, index) {
			this.PriceType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 视频价格区间
		VideoPriceShow(item, index) {
			this.VideoPriceType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 粉丝数切换
		FansNumShow(item, index) {
			this.FansNumType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 受众性别
		SexShow(item, index) {
			this.SexType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 受众年龄
		AgeShow(item, index) {
			this.AgeType = {
				title: item,
				id: index ? parseInt(index) : 0,
			}
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 特殊类目切换
		SpecialShow(item) {
			this.SpecialType = item
			console.log(item);
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 切换页
		currentTab(val) {
			this.page = val
			this.ListFn()
			this.TabCheck()
		},
		// 上一页
		prevClick() {
			this.page -= 1
			this.ListFn()
			this.TabCheck()
		},
		// 下一页
		// next-click
		nextClick() {
			this.page += 1
			this.ListFn()
			this.TabCheck()
		},
		// 是否切换收藏列表
		collectShow(id) {
			this.is_collect = id
			this.page = this.pageChangeOne()
			this.ListFn()
		},
		// 获取列表数据
		ListFn() {
			let data = {}
			data.page = this.page
			data.category_id = 6
			data.limit = 20
			if (this.ChannelType.id != 0) {
				data.industry_id = this.ChannelType.id
			}
			if (this.TerritoryType.id != 0) {
				data.area_id = this.TerritoryType.id
			}
			if (this.MoneyType.id != 0) {
				data.price_id = this.MoneyType.id
			}
			if (this.FansNumType.id != 0) {
				data.fans_num = this.FansNumType.id
			}
			if (this.PriceType.title != '不限') {
				data.image_text_price = this.PriceType.id
			}
			if (this.VideoPriceType.title != '不限') {
				data.video_price = this.VideoPriceType.id
			}
			if (this.SexType.title != '不限') {
				data.audience_sex = this.SexType.id
			}
			if (this.AgeType.title != '不限') {
				data.audience_age = this.AgeType.id
			}
			if (this.SpecialType.id == 1) {
				data.is_pub_weekend = 1
			} else if (this.SpecialType.id == 2) {
				data.is_pub_video = 1
			}
			if (this.SortType.title == '图文价格') {
				data.sort = 'member_image_text_price'
				if (this.MoneyState == 1) {
					data.order = 'asc'
				} else {
					data.order = 'desc'
				}
			} else if (this.SortType.title == '视频价格') {
				data.sort = 'member_video_price'
				if (this.VideoMoneyState == 1) {
					data.order = 'asc'
				} else {
					data.order = 'desc'
				}
			}
			if (this.mediaName !== '') {
				data.title = this.mediaName
			}
			if (this.link != '' && this.link == '是') {
				data.is_pub_link = 1
			} else if (this.link !== '' && this.link == '否') {
				data.is_pub_link = 0
			}
			if (this.relation !== '' && this.relation == '是') {
				data.is_pub_contact = 1
			} else if (this.relation !== '' && this.relation == '否') {
				data.is_pub_contact = 0
			}
			if (this.is_collect) {
				data.collect = this.is_collect
			}
			this.fullscreenLoading = true
			this.curlGet('/api/medium/list', data).then((res) => {
				if (res.data.code) {
					this.tableData = res.data.data.list
					this.total_page = res.data.data.total_page
					this.count = res.data.data.count
					this.tableData.forEach((item, index) => {
						this.$set(this.tableData[index], 'check', '')
						this.CarList.forEach((el, i) => {
							if (el.check && item.id == el.id) {
								this.$set(this.tableData[index], 'check', el.check)
							}
						})
					})
					this.shopDate(this.tableData, this.$store.state.shopObj, this.CarList)
				}
			})
			this.fullscreenLoading = false
		},
		// 重置选择
		AllMediaTab() {
			this.ChannelType = {
				id: 0,
				title: '不限',
			}
			this.TerritoryType = {
				id: 0,
				title: '不限',
			}
			this.PriceType = {
				id: 0,
				title: '不限',
			}
			this.MoneyType = {
				id: 0,
				title: '不限',
			}
			this.VideoPriceType = {
				id: 0,
				title: '不限',
			}
			this.FansNumType = {
				id: 0,
				title: '不限',
			}
			this.SpecialType = {
				id: 0,
				title: '不限'
			}
			this.SortType = {
				id: 0,
				title: '默认',
			}
			this.SexType = {
				id: 0,
				title: '不限'
			}
			this.AgeType = {
				id: 0,
				title: '不限'
			}
			this.AgeType = {
				id: 0,
				title: '不限'
			}
			this.page = this.pageChangeOne()
			this.mediaName = ''
			this.link = ''
			this.relation = ''

			this.ListFn()
		},
		// 判断切换数据时是否选中该条数据
		TabCheck() {
			this.AllcheckNum = 0
			this.fullscreenLoading = true
			setTimeout(() => {
				this.tableData.forEach((item, index) => {
					if (this.ids.indexOf(item.id) >= 0) {
						this.AllcheckNum += 1
						// if(this.AllcheckNum == this.limit){
						//     this.Allcheck = true
						// }
						return this.tableData[index].check = true
					}
				})
				this.fullscreenLoading = false

			}, 1000)
		},
		// 删除
		detale(element) {
			this.CarList.forEach((el, i) => {
				if (el.id == element.id) {
					this.CarList.splice(i, 1)
				}
			})
			// this.CarList.splice(index,1)
			this.tableData.forEach((item, index) => {
				if (item.id == element.id) {
					this.tableData[index].check = ''
				}
			})
		},
		// 立即投放
		putFn() {
			if (this.CarList.length == 0) {
				return this.$message({
					type: 'warning',
					message: '请选择媒介',
				})
			} else if (!this.$user_info) {
				this.$message({
					type: 'warning',
					message: '请登录',
				})
			} else {
				this.fullscreenLoading = true
				this.CarList.forEach((item, index) => {
					this.CarList[index].price = this.CarList[index][this.CarList[index].check]
					console.log(this.CarList[index]);
				})
				setTimeout(() => {
					this.fullscreenLoading = false
					this.$store.commit('shopPush', this.CarList)
					if (this.$store.state.fillActive == false) {

						this.$router.push('/user/SetMenu/ManuscriptPreview')
					} else {
						this.$store.commit('childTab', '小红书发布')
						this.$router.push({
							path: '/user/SetMenu/fill?id=6',
						})
					}
				}, 500)
			}
		},
		// 收藏
		collectGet(item) {
			if (!this.user_info) {
				this.$message({
					type: 'warning',
					message: '请登录',
					offset: 90,
				})
			} else {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					if (res.data.code) {
						this.$message({
							message: '收藏成功',
							type: 'success'
						})
						this.ListFn()
					}
				})
			}
		},
		// 取消收藏
		collectClose(item) {
			this.$confirm('是否取消收藏?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.curlGet('/api/medium/collect', {
					mid: item.id,
					style: 1
				}).then(res => {
					if (res.data.code) {
						this.$message({
							type: 'success',
							message: '取消收藏成功!'
						});
						this.ListFn()
					}
				})

			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消'
				});
			});

		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/Media';
@import '../../scss/overText';
</style>